<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('users.newuser') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            vid="email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                placeholder="john@example.com"
                autocomplete="off"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            vid="name"
            rules="required"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.name"
                :state="getValidationState(validationContext)"
                :placeholder="$t('profile.nameexample')"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            :name="$t('stats.country')"
            rules="required"
          >
            <b-form-group
              :label="$t('stats.country')"
              label-for="i-country"
              :class="validationContext.errors.length == 0 || 'is-invalid'"
            >
              <v-select
                id="i-country"
                v-model="country"
                :placeholder="$t('sites.choosecountry')"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[{ value: 'select_country', text: $t('sites.choosecountry') }, ...countries]"
                :clearable="false"
                :selectable="option => ! option.value.includes('select_country')"
                label="text"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Currency -->
          <validation-provider
            #default="validationContext"
            name="currency"
            rules="required"
          >
            <b-form-group
              :label="$t('Currency')"
              label-for="currency"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currency"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('registration.accountcurrency')"
                :options="[{ value: 'select_currency', text: $t('registration.accountcurrency') }, ...currencies]"
                :clearable="false"
                :selectable="option => ! option.value.includes('select_currency')"
                label="text"
                input-id="currency"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="phone"
            rules="required"
          >
            <b-form-group
              :label="$t('profile.phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.phone"
                :state="getValidationState(validationContext)"
                :placeholder="$t('profile.phoneexample')"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- contacts -->
          <b-form-group
            :label="$t('page.contacts.title')"
          >
            <div
              v-for="(contact, index) in userData.contacts"
              :key="index"
              class="contacts mb-1"
            >
              <validation-provider
                #default="validationContext"
                :name="socials[contact.type].text"
                rules="required"
              >
                <b-input-group
                  :class="{
                    'is-invalid': validationContext.errors.length > 0,
                  }"
                >
                  <b-input-group-prepend>
                    <b-dropdown
                      variant="outline-secondary"
                      class=""
                    >
                      <template #button-content>
                        <b-img
                          width="16"
                          height="16"
                          class="align-middle"
                          :src="socials[contact.type].src"
                        />
                      </template>
                      <b-dropdown-item
                        v-for="(key, idx) in Object.keys(socials)"
                        :key="idx"
                        @click="contactTypeUpdate(key, index)"
                      >
                        <b-img
                          width="19"
                          height="19"
                          :src="socials[key].src"
                        /> {{ socials[key].text }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userData.contacts[index].value"
                    :state="validationContext.errors.length > 0 ? false:null"
                    :placeholder="socials[contact.type].text"
                  />
                  <b-input-group-append>
                    <b-button
                      v-if="index === 0"
                      variant="outline-primary"
                      @click="contactAdd()"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="cursor-pointer"
                      />
                    </b-button>
                    <b-button
                      v-if="index > 0"
                      variant="outline-danger"
                      @click="contactRemove(index)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="cursor-pointer"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>

          <!-- password -->
          <b-form-group
            label-for="register-password"
            :label="$t('registration.password')"
          >
            <validation-provider
              #default="validationContext"
              name="Password"
              vid="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="validationContext.errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="register-password"
                  v-model="userData.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="validationContext.errors.length > 0 ? false:null"
                  name="register-password"
                  placeholder="············"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('registration.confirmpassword')"
            label-for="i-c-password"
          >
            <validation-provider
              #default="validationContext"
              name="Re-type Password"
              rules="required|confirmed:password"
            >
              <b-input-group
                class="input-group-merge"
                :class="validationContext.errors.length == 0 || 'is-invalid'"
              >
                <b-form-input
                  id="i-c-password"
                  v-model="RetypePassword"
                  :state="validationContext.errors.length > 0 ? false:null"
                  :type="passwordFieldTypeRetype"
                  placeholder="············"
                  autocomplete="confirm-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('form.create-acc') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('form.cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BImg,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'

export default {
  components: {
    BImg,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    vSelect,
    BDropdown,
    BDropdownItem,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      currencies: options.currencyList,
      countries: options.target_countries,
      passwordFieldType: 'password',
      passwordFieldTypeRetype: 'password',
      socials: {
        /* eslint-disable global-require */
        telegram: {
          src: require('@/assets/images/icons/telegram-brands.svg'),
          text: 'Telegram',
        },
        whatsupp: {
          src: require('@/assets/images/icons/whatsapp-brands.svg'),
          text: 'Whatsapp',
        },
        viber: {
          src: require('@/assets/images/icons/viber-brands.svg'),
          text: 'Viber',
        },
        skype: {
          src: require('@/assets/images/icons/skype-brands.svg'),
          text: 'Skype',
        },
        other: {
          src: require('@/assets/images/icons/speaker.svg'),
          text: 'Other',
        },
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
  setup(_, { emit }) {
    const toast = useToast()
    const RetypePassword = ref('')
    const currency = ref(null)
    const country = ref(null)
    const blankUserData = {
      affiliate: '',
      email: '',
      name: '',
      password: '',
      phone: '',
      balance: 0,
      is_admin: false,
      created_at: null,
      currency: null,
      owns: null,
      is_agency_member: false,
      is_agency: false,
      roles: [],
      show_amount: false,
      country: null,
      contacts: [
        { type: 'telegram', value: '' },
      ],
      notes: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const contactTypeUpdate = (type, index) => {
      userData.value.contacts[index].type = type
    }

    const contactAdd = () => {
      userData.value.contacts.push({ type: 'telegram', value: '' })
    }

    const contactRemove = id => {
      userData.value.contacts.splice(id, 1)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const onSubmit = async () => {
      userData.value.country = country.value.value !== null ? country.value.value : null
      userData.value.currency = currency.value.value !== null ? currency.value.value : null

      await useJwt.register({ account: userData.value }).then(() => {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Register',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'You have successfully register',
          },
        })
        emit('update:is-add-new-user-sidebar-active', false)
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.errors[0].detail,
            },
          })
          refFormObserver.value.setErrors({ email: error.response.data.errors[0].detail })
        }
      })
    }

    return {
      currency,
      country,
      RetypePassword,
      userData,
      onSubmit,
      contactAdd,
      contactTypeUpdate,
      contactRemove,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
