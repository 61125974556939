<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />
    <b-card
      :title=" $t('Users')"
    >
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else-if="rows.length"
        class="users-table"
      >
        <!-- search input -->
        <div class="custom-search">
          <div class="total">
            {{ $t('users.all_users') }}: {{ totals }}
          </div>
          <div class="table-filters">
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('users.usersroles') }}</label>
              <b-form-select
                :value="filterRole"
                :options="[{ value: '', text: this.$t('users.all') }, ...optionsRoles]"
                @change="(value) => filterRole = value"
              />
            </div>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('Currency') }}</label>
              <b-form-select
                :value="filterCurrency"
                :options="[{ value: '', text: this.$t('users.all') }, ...optionsCurrencies]"
                @change="(value) => filterFn('currency', value)"
              />
            </div>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('message.seachLabel') }}</label>
              <b-input-group>
                <b-form-input
                  ref="searchInput"
                  :value="searchTerm"
                  :placeholder=" $t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="searchTerm = $event.target.value"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="searchTerm = $refs.searchInput.vModelValue"
                  >
                    <feather-icon
                      icon="SearchIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <b-button-toolbar
              justify
            >
              <b-button-group>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                  />
                  {{ $t('profile.useradd') }}
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="filterRows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'name', type: 'asc'}
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          style-class="vgt-table striped"
        >

          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.name') }}
            </span>
            <span
              v-else-if="props.column.label ==='Phone'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.phone') }}
            </span>
            <span
              v-else-if="props.column.label ==='Balance'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.balance') }}
            </span>
            <span
              v-else-if="props.column.label ==='Action'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.action') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'name'"
              class="text-nowrap"
            >
              <router-link :to="'/users/'+ props.row._id"><strong>{{ props.row.name }}</strong></router-link>
              <small
                v-if="props.row.owns.sites"
                class="badge badge-secondary ml-25"
                :title="$t('selects.filter.publishers')"
              >P</small>
              <small
                v-if="props.row.owns.campaigns"
                class="badge badge-warning ml-25"
                :title="$t('selects.filter.advertisers')"
              >A</small>
              <small
                v-if="is_new(props.row.created_at)"
                class="badge badge-info ml-25"
                :title="$t('selects.filter.new')"
              >NEW</small>
              <br>
              <small>{{ props.row.email }}</small>
            </span>

            <!-- Column: Country -->
            <span
              v-if="props.column.field === 'phone'"
              class="text-nowrap"
            >
              {{ props.row.phone }}
            </span>

            <span
              v-if="typeof props.column.field === 'function'"
              class="text-nowrap"
            >
              {{ props.row.owns.sites || 0 }}
            </span>

            <!-- Column: Balance -->
            <span
              v-if="props.column.field === 'balance'"
              class="text-nowrap"
            >
              {{ currency(props.row.currency, props.row.balance) }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  right
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :href="'/users/'+ props.row._id + '/edit'"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.change') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    @click="deleteEntity('accounts', props.row._id, props.row.name)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <!-- Column: Common -->
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  {{ totals = props.total }}
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>

</template>

<script>
import currencyFormatter from '@/utils/currency-formatter'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BSpinner,
  BPagination,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BButtonToolbar,
  BButtonGroup,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    BSpinner,
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    UserListAddNew,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isAddNewUserSidebarActive: false,
      currency: currencyFormatter,
      loading: true,
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'E-mail',
          field: 'email',
          hidden: true,
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Sites',
          field: this.fieldFn,
          type: 'number',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Currency',
          field: 'currency',
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          hidden: true,
        },
        {
          label: 'Balance',
          field: 'balance',
          type: 'number',
        },
        {
          label: 'Action',
          width: '5%',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      rows: [],
      totals: 0,
      searchTerm: '',
      optionsRoles: [
        { value: 'is_new', text: this.$t('selects.filter.new') },
        { value: 'is_publisher', text: this.$t('selects.filter.publishers') },
        { value: 'is_advertiser', text: this.$t('selects.filter.advertisers') },
        { value: 'is_admin', text: this.$t('selects.filter.admins') },
      ],
      optionsCurrencies: [
        { text: 'USD', value: 'USD' },
        { text: 'UAH', value: 'UAH' },
        { text: 'RUB', value: 'RUB' },
        { text: 'EUR', value: 'EUR' },
        { text: 'ILS', value: 'ILS' },
        { text: 'UZS', value: 'UZS' },
        { text: 'BYN', value: 'BYN' },
        { text: 'BRL', value: 'BRL' },
        { text: 'INR', value: 'INR' },
        { text: 'THB', value: 'THB' },
      ],
      filterRole: '',
      filterCurrency: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filterRows() {
      let filtred = []
      if (this.filterRole !== '') {
        if (this.filterRole === 'is_new') {
          filtred = this.rows.filter(r => (new Date() - new Date(r.created_at)) <= (7 * 24 * 60 * 60 * 1000))
        } else if (this.filterRole === 'is_publisher') {
          filtred = this.rows.filter(r => r.owns.sites)
        } else if (this.filterRole === 'is_advertiser') {
          filtred = this.rows.filter(r => r.owns.campaigns)
        } else if (this.filterRole === 'is_admin') {
          filtred = this.rows.filter(r => r.is_admin)
        }
        return filtred
      }
      return this.rows
    },
  },
  created() {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers() {
      this.loading = true
      const responseData = await useJwt.users()
      this.rows = responseData.data.accounts || []
      this.loading = false
    },
    is_new(date) {
      return (new Date() - new Date(date)) <= (7 * 24 * 60 * 60 * 1000)
    },
    checkEnter(event) {
      if (event.code !== 'Enter') {
        event.preventDefault()
      }
    },
    fieldFn(rowObj) {
      return rowObj.owns.sites
    },
    filterFn(field, value) {
      const found = this.columns.find(c => c.field === field)
      if (found) {
        // eslint-disable-next-line no-prototype-builtins
        if (found.hasOwnProperty('filterOptions')) {
          // eslint-disable-next-line no-prototype-builtins
          if (found.filterOptions.hasOwnProperty('filterValue')) {
            found.filterOptions.filterValue = value
          }
        }
      }
    },
    deleteEntity(entity, id, title) {
      this.$swal({
        title: `${this.$t('swal.deletetitle')} ${title}?`,
        text: this.$t('swal.deletemessage'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('form.cancel'),
        confirmButtonText: this.$t('form.delete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteEntity(entity, id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.fetchUsers()
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.users-table table .btn {
  padding: inherit;
}
</style>
